import React, { useEffect, useState } from "react";

import styled from "styled-components";

import ReferenceTagTreeView from "../../organisms/reference/ReferenceTagTreeView";

import GeneralText from "components/atoms/text/GeneralText";

import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceTagRecommendModal from "components/page/modal/ReferenceTagRecommendModal";

import { useTagReferenceCreate, useTags } from "hooks/queries/useBibliographies";

import Constants from "utils/constants";

const Container = styled.div`
    width: 100%;
`;

const MessageLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
`;

const RecommendationButton = styled.div`
    cursor: pointer;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 40px 0;
    width: 100%;
    height: 320px;
`;

function ReferenceTagEditModal(props) {
    const [selectedTags, setSelectedTags] = useState([]);
    const [showTagRecommendModal, setShowTagRecommendModal] = useState(false);

    const tagReferenceCreate = useTagReferenceCreate();

    const tagQuery = useTags();
    const refreshSelectedTags = () => {
        tagQuery.refetch();
    };

    useEffect(() => {
        if (props.selectedTagIds) {
            setSelectedTags(props.selectedTagIds);
        }
    }, [props.selectedTagIds]);

    return (
        <Container>
            <MessageLine>
                <GeneralText size={"regular"}>이 참고문헌에 적용할 태그를 선택해주세요.</GeneralText>
                {props.referenceFile && (
                    <RecommendationButton onClick={(e) => setShowTagRecommendModal(true)}>
                        태그 추천
                    </RecommendationButton>
                )}
            </MessageLine>

            <ContentsWrapper>
                <ReferenceTagTreeView
                    treeId={Constants.TAG_TREE_ID_EDIT_MODAL}
                    viewState={{
                        [Constants.TAG_TREE_ID_EDIT_MODAL]: {
                            selectedItems: selectedTags,
                        },
                    }}
                    onSelectItems={(tags) => {
                        let diff = tags
                            .filter((x) => !selectedTags.includes(x))
                            .concat(selectedTags.filter((x) => !tags.includes(x)));

                        if (diff.length > 0) {
                            tagReferenceCreate.mutate(
                                {
                                    reference_id: props.referenceId,
                                    tag_ids: tags,
                                    recommended_tags: [],
                                },
                                {
                                    onSuccess: (data, variables, context) => {},
                                    onError: (error, variables, context) => {
                                        console.log(error);
                                    },
                                },
                            );
                        }
                    }}
                />
            </ContentsWrapper>
            {showTagRecommendModal && (
                <AbstractModal
                    width={480}
                    modalTitle="태그 추천"
                    exitModal={(e) => {
                        setShowTagRecommendModal(false);
                    }}
                >
                    <ReferenceTagRecommendModal
                        referenceId={props.referenceId}
                        selectedTagIds={props.selectedTagIds}
                        refreshSelectedTags={refreshSelectedTags}
                        exitModal={(e) => {
                            setShowTagRecommendModal(false);
                        }}
                    />
                </AbstractModal>
            )}
        </Container>
    );
}

export default ReferenceTagEditModal;
