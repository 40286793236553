import { useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import SentenceText from "components/atoms/text/SentenceText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import { toBibJson } from "utils/scienceon";

const Container = styled.div`
    padding: 16px 16px;
    width: 100%;
    border-bottom: solid 1px #d6e8f1;
`;

const ItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const HeaderLeft = styled.div`
    display: flex;
    align-itmes: center;
    width: calc(100% - 32px);
`;

const RowWrap = styled.div`
    display: flex;
`;

const ItemNumber = styled.div`
    position: relative;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: var(--color-Key);
`;

const TitleButton = styled.button`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    background-color: transparent;
    border: transparent;
    outline: none;

    &:hover {
        p {
            text-decoration: underline;
            color: var(--color-Black);
        }
    }
`;

const ToggleSign = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0 0 0;
    width: 12px;
    height: 12px;
`;

const InfoWrap = styled.div`
    width: 100%;
    margin: 8px 0 0 0;
`;

const InfoItem = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 0 0 4px 0;
    min-height: 24px;

    &:last-child {
        margin: 0;
    }
`;

const IconWrap = styled.div`
    position: relative;
    top: 4px;
    display: flex;
    align-items: center;
    margin: 0 12px 0 0;
    width: 16px;
    height: 16px;
`;

const PublisherItem = styled.span`
    display: inline-block;
    margin: 0 0 0 8px;

    &:first-child {
        margin: 0;
    }
`;

const AuthorItem = styled.span`
    display: inline-block;
    margin: 0 0 0 8px;

    &:first-child {
        margin: 0;
    }
`;

const KeywordItem = styled.span`
    display: inline-block;
    margin: 0 0 0 8px;

    &:first-child {
        margin: 0;
    }
`;

const DOIWrap = styled.div`
    display: flex;
    align-items: center;

    a {
        margin: 0 4px 0 0;

        &:last-child {
            margin: 0;
        }
    }
`;

const LinkButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    min-width: 56px;
    height: 18px;
    border-radius: 2px;
    border: transparent;
    background-color: var(--color-Button3);
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover2);
    }
`;

const HideSection = styled.div`
    // display: none;
`;

const AbstractWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 0 12px 0;
`;

const AbstractText = styled.span``;

const LinkButtonStyle = {
    width: "56px",
    height: "18px",
    borderRadius: "2px",
    bgColor: "var(--color-Button2)",
    hoverBgColor: "var(--color-ButtonHover2)",
    fontSize: "xsmall",
};

function SearchedPaperItem(props) {
    const { setToast } = useToast();

    const handleQuoteButtonClicked = (e) => {
        if (props.user && props.user.is_authenticated) {
            let bib_json = toBibJson(props.data);

            props.mutation.mutate(
                {
                    body: {
                        bib_json: bib_json,
                        display_name: props.data.title,
                    },
                },
                {
                    onSuccess: (data, variables, context) => {
                        if (props.research && props.researchReferenceMutation) {
                            let references_ids = props.research.references.map((reference) => reference.id);
                            references_ids.push(data.id);

                            props.researchReferenceMutation.mutate({
                                research_id: props.research.id,
                                reference_ids: references_ids,
                            });
                        }
                        setToast("참고문헌을 등록했습니다.", "info");
                    },
                },
            );
        } else {
            Alert("info", "안내", "로그인 후 사용할 수 있는 기능입니다.");
        }
    };

    const [isHideSectionVisible, setIsHideSectionVisible] = useState(false);
    const handleHideSection = () => {
        setIsHideSectionVisible((prev) => !prev);
    };

    return (
        <Container>
            <ItemHeader>
                <HeaderLeft>
                    <RowWrap>
                        {props.index <= 5 && (
                            <ItemNumber>
                                <GeneralText size={"xsmall"} fontWeight={"500"} color={"var(--color-White)"}>
                                    {props.index}
                                </GeneralText>
                            </ItemNumber>
                        )}
                        <TitleButton onClick={handleHideSection}>
                            <GeneralText margin={"2px 4px 0 0"} size={"regular"} color={"var(--color-SubBlack)"}>
                                {props.data.title}
                            </GeneralText>
                            <ToggleSign>
                                <Icon
                                    name={isHideSectionVisible ? "arrowUp" : "arrowDown"}
                                    color={"var(--color-SubBlack)"}
                                />
                            </ToggleSign>
                        </TitleButton>
                    </RowWrap>
                </HeaderLeft>
                <Tooltip message="참고문헌에 추가" position={props.research ? "left" : "bottom"}>
                    <Button
                        onlyIcon
                        bgColor={"transparent"}
                        hoverBgColor={"var(--color-ButtonHover4)"}
                        onClick={handleQuoteButtonClicked}
                    >
                        <Icon name={"quote"} size={"8"} color={"var(--color-SubBlack)"} />
                    </Button>
                </Tooltip>
            </ItemHeader>
            <InfoWrap>
                {props.data.publisher && (
                    <InfoItem>
                        <IconWrap>
                            <Icon name={"publisher"} size={"16"} color={"var(--color-Grey2)"} />
                        </IconWrap>
                        <GeneralText size={"small"} margin={"3px 0 0 0"}>
                            <PublisherItem>
                                {props.data.journal
                                    ? props.data.journal
                                    : `${props.data.publisher} (${props.data.degree})`}
                                , {props.data.year}
                            </PublisherItem>
                        </GeneralText>
                    </InfoItem>
                )}
                {props.data.authors && props.data.authors.length > 0 && (
                    <InfoItem>
                        <IconWrap>
                            <Icon name={"user"} size={"16"} color={"var(--color-Grey2)"} />
                        </IconWrap>
                        <GeneralText size={"small"} margin={"3px 0 0 0"}>
                            <AuthorItem>{props.data.authors.join(", ")}</AuthorItem>
                        </GeneralText>
                    </InfoItem>
                )}
            </InfoWrap>
            <HideSection style={{ display: isHideSectionVisible ? "initial" : "none" }}>
                <AbstractWrap>
                    <TitleText withBarType size={"subRegular"} color={"var(--color-SubBlack)"}>
                        초록
                    </TitleText>
                    <SentenceText size={"regular"} margin={"8px 0 0 8px"}>
                        {props.data.abstract && props.data.abstract.length > 0 ? (
                            <AbstractText dangerouslySetInnerHTML={{ __html: props.data.abstract }}></AbstractText>
                        ) : (
                            <AbstractText style={{ color: "var(--color-Grey1)" }}>
                                미리 볼 수 있는 초록이 없습니다.
                            </AbstractText>
                        )}
                    </SentenceText>
                </AbstractWrap>
                {props.data.keyword && (
                    <InfoItem>
                        <IconWrap>
                            <Icon name={"tag"} size={"16"} color={"var(--color-Grey2)"} />
                        </IconWrap>
                        <GeneralText size={"small"} margin={"3px 0 0 0"}>
                            <KeywordItem>{props.data.keyword}</KeywordItem>
                        </GeneralText>
                    </InfoItem>
                )}
                <InfoItem style={{ alignItems: "center" }}>
                    <IconWrap style={{ top: "0px" }}>
                        <Icon name={"link"} size={"16"} color={"var(--color-Grey2)"} />
                    </IconWrap>
                    <DOIWrap>
                        {props.data.doi && (
                            <Link to={props.data.doi} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"DOI"} />
                            </Link>
                        )}
                        <Link
                            to={"https://scholar.google.com/scholar?q=" + encodeURIComponent(props.data.title)}
                            target={"_blank"}
                        >
                            <Button onlyText {...LinkButtonStyle} buttonText={"Google"} />
                        </Link>
                        {props.data.scienceon_content_url && (
                            <Link to={props.data.scienceon_content_url} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"ScienceON"} />
                            </Link>
                        )}
                        {props.data.scienceon_fulltext_url && (
                            <Link to={props.data.scienceon_fulltext_url} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"PDF"} />
                            </Link>
                        )}
                    </DOIWrap>
                </InfoItem>
            </HideSection>
        </Container>
    );
}

export default SearchedPaperItem;
