import React, { useEffect, useRef, useState } from "react";
import { useIsFetching, useIsMutating, useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import styled, { css } from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import SentenceText from "components/atoms/text/SentenceText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import ReferenceAddMenu from "components/organisms/reference/ReferenceAddMenu";
import ReferencePaperItem from "components/organisms/reference/ReferencePaperItem";
import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceAddArxivModal from "components/page/modal/ReferenceAddArxivModal";
import ReferenceAddBibtexModal from "components/page/modal/ReferenceAddBibtexModal";
import ReferenceAddDoiModal from "components/page/modal/ReferenceAddDoiModal";
import ReferenceAddPubmedModal from "components/page/modal/ReferenceAddPubmedModal";
import ReferenceFileUploadModal from "components/page/modal/ReferenceFileUploadModal";
import LoginUserLayout from "components/templates/LoginUserLayout";

import { useBibliographyUpdate } from "hooks/queries/useBibliographies";
import { useBibliographies, useBibliographyDelete } from "hooks/queries/useBibliographies";

import { useDevice } from "utils/device";
import { postBibliography } from "utils/request/bibliography";
import { permissionErrorHandler } from "utils/request/common";

// needed for table body level scope DnD setup
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, horizontalListSortingStrategy } from "@dnd-kit/sortable";
// needed for row & cell level scope DnD setup
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import moment from "moment/moment";

const Header = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    height: 48px;
    max-height: 48px;
    background-color: var(--color-White);
    border-bottom: solid 1px var(--color-Line);
`;

const HeaderLeftWrap = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderRightWrap = styled.div`
    position: relative;
    margin: 0 0 0 40px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 48px 0 0 0;
    padding: 0 48px;
    width: 100%;
    height: calc(100vh - 104px);
`;

const BodyHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
`;

const BodyHeaderLeftSection = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    width: 171px;
`;

const BodyHeaderRightSection = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0 24px;
`;

const SearchWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px 0 0;
    width: 100%;
    min-width: 200px;
    max-width: 320px;
`;

const SearchInputBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 4px;
    width: 100%;
    height: 28px;
    background-color: var(--color-White);
    border: solid 1px var(--color-Line);
    border-radius: 4px;

    &:focus-within {
        border: solid 1px var(--color-Key);
    }
`;

const SearchInput = styled.input`
    padding: 0 8px;
    width: calc(100% - 24px);
    height: 24px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-Black);
    background-color: transparent;
    border: transparent;
    outline: var(--color-Key);
`;

const SearchButtonWrap = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
`;

const BookmarkWrap = styled.div`
    display: flex;
    align-items: center;
    min-width: 113px;
`;

const ViewButtonWrap = styled.div`
    display: flex;
    align-items: center;
`;

const OptionButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.optionBoxActive ? "var(--color-White)" : "var(--color-Button4)")};
    border: solid 1.5px ${(props) => (props.optionBoxActive ? "var(--color-Button2)" : "transparent")};
    border-radius: 4px;
    outline: none;

    &:hover {
        background-color: ${(props) => (props.optionBoxActive ? "" : "var(--color-ButtonHover4)")};
    }
`;

const ViewButton = styled.button`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 4px 0 0;
    padding: 0 6px;
    width: 100px;
    height: 24px;
    background-color: ${(props) => (props.activeViewMenu ? "var(--color-White)" : "var(--color-Button4)")};
    border: solid 1.5px ${(props) => (props.activeViewMenu ? "var(--color-Button2)" : "transparent")};
    border-radius: 4px;
    outline: none;

    &:last-child {
        margin: 0;
    }

    &:hover {
        background-color: ${(props) => (props.activeViewMenu ? "" : "var(--color-ButtonHover4)")};
    }
`;

const DivideLine = styled.span`
    display: block;
    margin: 0 12px;
    width: 1px;
    height: 16px;
    background-color: var(--color-Outline);
`;

const ViewMenuContainer = styled.div`
    position: absolute;
    top: 28px;
    right: 0;
    left: 0;
    width: 100px;
    height: ${(props) => (props.menuActive ? "auto" : "0px")};
    background-color: var(--color-White);
    border-radius: 4px;
    border: solid 1.5px var(--color-DarkKey);
    opacity: ${(props) => (props.menuActive ? "1" : "0")};
    z-index: 1;
`;

const ViewMenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    width: 100px;
    height: 24px;
`;

const OptionBoxContainer = styled.div`
    position: absolute;
    top: 28px;
    right: 0;
    display: ${(props) => (props.visible ? "block" : "none")};
    padding: 8px;
    width: ${(props) => props.width};
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    z-index: 1;
`;

const CheckOptionWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    max-height: 200px;
`;

const CheckItemWrap = styled.div``;

const CheckRadioItemLabel = styled.label`
    display: flex;
    align-items: center;
    height: 24px;
`;

const RadioItemWrap = styled.div``;

const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;

    ${(props) =>
        props.isFileDragging &&
        css`
            border: dashed 2px var(--color-Key);
            background-color: var(--color-Base1);
        `};
`;

const BodyContentUploadView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const UploadDropBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: dashed 2px var(--color-Key);
    background-color: var(--color-Base1);
`;

const BodyContentListView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const ListViewTableWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const ListViewTable = styled.table`
    width: 100%;
`;

const TableHead = styled.thead`
    width: 100%;
`;

const TableHeadTr = styled.tr`
    th {
        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
`;

const TableHeadTh = styled.th`
    position: relative;
    height: 32px;

    .sortHandle,
    .resizeHandle {
        display: none;
    }

    &:hover {
        .sortHandle,
        .resizeHandle {
            display: block;
        }

        .resizeHandle {
            user-select: none;
        }
    }
`;

const TableHeadThTitle = styled.div`
    width: 100%;
    cursor: ${(props) => props.cursor};
`;

const TableHeadThSortHandle = styled.div`
    position: absolute;
    top: 6px;
    right: 8px;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;

    &:hover {
        background-color: #aaccc6;
    }
`;

const ResizeHandle = styled.div`
    position: absolute;
    top: 6px;
    right: 0;
    width: 4px;
    height: 24px;
    background-color: var(--color-Button2);
    cursor: col-resize;
`;

const TableBody = styled.tbody`
    > tr {
        height: 48px;
        border-bottom: solid 1px var(--color-Outline);
        cursor: pointer;

        &:hover {
            transition: all 80ms ease-in;
            background-color: #e9f2f4;
        }
    }
`;

const TableCell = styled.td``;
const TableCellContentWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const RefTableHeader = ({ header }) => {
    const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
        id: header.column.id,
    });

    const isDraggable = !["id", "is_bookmarked"].includes(header.column.id);
    const draggableProps = isDraggable ? { ...attributes, ...listeners, style: { cursor: "grab" } } : {};

    const style = {
        opacity: isDragging ? 0.8 : 1,
        padding: "8px 6px",
        backgroundColor: "var(--color-Base2)",
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: "width transform 0.2s ease-in-out",
        whiteSpace: "nowrap",
        textAlign: "center",
        zIndex: isDragging ? 1 : 0,
    };

    return (
        <TableHeadTh key={header.id} colSpan={header.colSpan} ref={setNodeRef} style={{ ...style }}>
            {header.isPlaceholder ? null : (
                <TableHeadThTitle {...draggableProps}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeadThTitle>
            )}
            {!["id", "is_bookmarked"].includes(header.column.id) && (
                <>
                    <TableHeadThSortHandle
                        className={"sortHandle"}
                        onMouseDown={
                            ["id", "is_bookmarked"].includes(header.column.id)
                                ? undefined
                                : header.column.getToggleSortingHandler()
                        }
                        title={
                            header.column.getCanSort() && !["id", "is_bookmarked"].includes(header.column.id)
                                ? header.column.getNextSortingOrder() === "asc"
                                    ? "오름차순으로 정렬"
                                    : header.column.getNextSortingOrder() === "desc"
                                    ? "내림차순으로 정렬"
                                    : "정렬 해제"
                                : undefined
                        }
                    >
                        {{
                            asc: "↓",
                            desc: "↑",
                        }[String(header.column.getIsSorted())] ?? "↕"}
                    </TableHeadThSortHandle>
                    <ResizeHandle
                        className={"resizeHandle"}
                        {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                        }}
                    >
                        &nbsp;
                    </ResizeHandle>
                </>
            )}
        </TableHeadTh>
    );
};

const DragAlongCell = ({ cell }) => {
    const { isDragging, setNodeRef, transform } = useSortable({
        id: cell.column.id,
    });
    const navigate = useNavigate();

    const style = {
        opacity: isDragging ? 0.8 : 1,
        padding: "8px 6px",
        height: "48px",
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: "width transform 0.2s ease-in-out",
        zIndex: isDragging ? 1 : 0,
    };

    return (
        <TableCell
            key={cell.id}
            ref={setNodeRef}
            style={{
                ...style,
                width: cell.column.getSize(),
            }}
            onClick={(e) => {
                if (!["id", "is_bookmarked", "file"].includes(cell.column.id)) {
                    navigate("/reference/" + cell.row.original.uuid);
                }
            }}
        >
            <TableCellContentWrap>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCellContentWrap>
        </TableCell>
    );
};

const ListViewPagination = styled.div`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    padding: 10px 0;
`;

const ListViewPageButtonWrap = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const ListViewPageSelectBoxWrap = styled.div`
    margin-left: auto;
`;

const BodyContentCardView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 24px 0;
    max-width: 1000px;
    height: 100%;
`;

const BodyContentBlankView = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const FileUploadSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AddInfoSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;

const AddInfoBox = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--color-InfoBox);

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 24px;
        `};
`;

const AddInfoItem = styled.div`
    width: 50%;
    max-width: calc(50% - 8px);
`;

const AddInfoItemRow = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 8px 6px;
    width: 100%;

    &:last-child {
        margin: 0 0 0 6px;
    }
`;

const AddInfoSubItemWrap = styled.div`
    margin: 0 0 8px 32px;
`;

const AddInfoSubItem = styled.div`
    display: flex;
    align-items: center;
`;

const AddFileBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    border-radius: 8px;
    border: dashed 2px var(--color-Outline);
    transition: all 150ms ease-in;

    ${(props) =>
        props.isFileDragging &&
        css`
            border: dashed 2px var(--color-Key);
            background-color: var(--color-Base1);
        `};
`;

const AddBoxIcon = styled.div`
    margin: 0 0 24px 0;
    width: 100px;
    height: 100px;
`;

const ResearchBox = styled.div`
    position: relative;
    margin: 0 2px 0 0;
    padding: 2px 4px;
    max-width: 200px;
    border-radius: 2px;
    background-color: var(--color-Research);

    &:last-child {
        margin: 0;
    }

    &:hover {
        .moreBox {
            display: block;
            z-index: 1;
        }
    }
`;

const TagBox = styled.div`
    position: relative;
    margin: 0 2px 0 0;
    padding: 2px 4px;
    border-radius: 2px;
    background-color: var(--color-Tag);

    &:last-child {
        margin: 0;
    }

    &:hover {
        .moreBox {
            display: block;
            z-index: 1;
        }
    }
`;

const MoreBox = styled.div`
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    padding: 8px;
    width: max-content;
    max-width: 240px;
    background-color: var(--color-White);
    border-radius: 4px;
    border: solid 1px var(--color-Outline);
`;

const MoreListWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: ${(props) => props.width};
    max-width: ${(props) => props.width};
    min-height: 24px;
    height: auto;
    max-height: 160px;
`;

const CheckIconStyle = {
    name: "check",
    size: "14",
    color: "var(--color-DarkKey)",
};

const ItemTextStyle = {
    margin: "0 0 0 8px",
    size: "large",
    color: "var(--color-SubBlack)",
};

const SubItemTextStyle = {
    margin: "0 0 4px 4px",
    size: "small",
    color: "var(--color-SubBlack)",
};

function ReferenceListPage(props) {
    const device = useDevice();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [bibliographyList, setBibliographyList] = useState([]);
    const checkedBibsRef = useRef([]);

    const [searchValue, setSearchValue] = useState("");
    const [order, setOrder] = useState("-created_at");

    const [isOpen, setIsOpen] = useState(false);
    const buttonWrapRef = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (buttonWrapRef.current && !buttonWrapRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [viewType, setViewType] = useState("list");

    const [modalState, setModalState] = useState({
        arxivModal: false,
        doiModal: false,
        pubmedModal: false,
        bibtexModal: false,
    });
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);

    const { setToast } = useToast();

    const bibliographiesQuery = useBibliographies({
        filter: searchParams.get("filter"),
        ordering: order,
    });
    const deleteBibliographyMutation = useBibliographyDelete();

    const { setLoading } = useLoading();
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    useEffect(() => {
        if (viewType === "list") {
            setOrder("-created_at");
        }
    }, [viewType]);

    {
        /* 초기화 */
    }
    useEffect(() => {
        return () => {
            bibliographiesQuery.remove();
        };
    }, []);

    useEffect(() => {
        setSearchValue(searchParams.get("filter") || "");
        bibliographiesQuery.refetch();
    }, [searchParams]);

    useEffect(() => {
        if (bibliographiesQuery.data) {
            setBibliographyList(bibliographiesQuery.data);
        }
    }, [bibliographiesQuery.data]);

    useEffect(() => {
        if (bibliographiesQuery.error) {
            permissionErrorHandler(bibliographiesQuery.error, navigate);
        }
    }, [bibliographiesQuery.error]);

    useEffect(() => {
        if (deleteBibliographyMutation.isSuccess) {
            setToast("참고문헌이 삭제되었습니다.", "info");
        }
    }, [deleteBibliographyMutation.isSuccess]);

    // Checkbox 전체 선택
    const handleCheckboxAllChange = (event) => {
        // 전체 선택이면 모든 id를, 그렇지 않으면 빈 배열을 반환
        checkedBibsRef.current = event.target.checked ? bibliographyList.map((item) => item.id) : [];
        rerenderTable();
    };

    const handleOpenAddButton = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleCheckboxChange = (event) => {
        let { id } = event.target;
        id = Number(id);

        const index = checkedBibsRef.current.indexOf(id);
        if (index > -1) {
            checkedBibsRef.current = checkedBibsRef.current.filter((item) => item !== id);
        } else {
            checkedBibsRef.current = [...checkedBibsRef.current, id];
        }
        rerenderTable();
    };

    // delete Function
    const deleteBibliography = () => {
        if (checkedBibsRef.current.length > 0) {
            let citation_exists = false;
            let message = "선택한 참고문헌을 삭제하시겠습니까?";
            bibliographyList
                .filter((item) => checkedBibsRef.current.includes(item.id))
                .map((item) => {
                    if (item.citation_count > 0) {
                        citation_exists = true;
                    }
                });
            if (citation_exists) {
                message = "리서치에 인용된 참고문헌입니다. 삭제하시겠습니까?";
            }
            Confirm("warn", "참고문헌 삭제", message, "삭제", (e) => {
                handleDeleteConfirm();
            });
        } else {
            Alert("warn", "에러", "삭제할 참고문헌을 선택해주세요.");
        }
    };

    const handleDeleteConfirm = () => {
        deleteBibliographyMutation.mutate({ ids: checkedBibsRef.current });
        checkedBibsRef.current = [];
    };

    // 참고 문헌 추가 mutation
    const bibliographyMutation = useMutation(postBibliography, {
        onSuccess: (data, variables, context) => {
            if (variables.body.file_url) {
                // arxiv 원문 파일 포함하여 추가한 경우
                navigate("/reference/" + data.uuid, {
                    state: { file: variables.body.file_url },
                });
            } else {
                // 파일 없이 추가한 경우 (직접 추가, BibTeX 추가, 외부 DB의 서지정보만 가져와서 추가 등)
                navigate("/reference/" + data.uuid);
            }
        },
        onError: (error, variables, context) => {
            if (error.response.status === 401) {
                Alert("warn", "에러", "로그인이 필요합니다.");
                navigate("/login");
            } else if (error.response.status === 400) {
                if (error.response.data.bib_json) {
                    if (error.response.data.bib_json.citation_key) {
                        Alert("warn", "에러", error.response.data.bib_json.citation_key);
                    } else {
                        Alert("warn", "에러", error.response.data.bib_json);
                    }
                } else {
                    Alert("warn", "에러", "참고문헌 추가에 실패했습니다.");
                }
            } else {
                Alert("warn", "에러", "서버에 오류가 발생했습니다.");
            }
        },
    });

    // drag & drop 파일 업로드
    const [isFileDragging, setIsFileDragging] = useState(false);
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFileDragging(true);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFileDragging(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFileDragging(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFileDragging(false);

        if (e.dataTransfer.files) {
            setUploadingFiles(Array.from(e.dataTransfer.files));
            setShowFileUploadModal(true);
        }
    };

    const [uploadingFiles, setUploadingFiles] = useState([]);
    const fileAddHandler = (e) => {
        setUploadingFiles(Array.from(e.target.files));
        setShowFileUploadModal(true);
    };

    const columns = React.useMemo(
        () => [
            {
                id: "id",
                accessorKey: "id",
                name: "선택",
                size: 30,
                disableResizing: true,
                header: null,
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <Checkbox
                            id={value}
                            checked={checkedBibsRef.current.includes(value)}
                            onChange={handleCheckboxChange}
                        />
                    );
                },
            },
            {
                id: "is_bookmarked",
                accessorKey: "is_bookmarked",
                name: "즐겨찾기",
                size: 30,
                disableResizing: true,
                header: () => "",
                cell: ({ row }) => {
                    return (
                        <Button
                            onlyIcon
                            onClick={() => handleBookmarkClick(row.original)}
                            bgColor={"transparent"}
                            hoverBgColor={"transparent"}
                        >
                            {row.original.is_bookmarked ? (
                                <Icon name={"bookmark"} color={"var(--color-Key)"} size={"16px"} />
                            ) : (
                                <Icon name={"bookmarkDisactive"} size={"16px"} />
                            )}
                        </Button>
                    );
                },
            },
            {
                id: "citation_key",
                accessorKey: "bib_json.citation_key",
                name: "인용키",
                size: 100,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {info.getValue()}
                        </SentenceText>
                    );
                },
            },
            {
                id: "display_name",
                accessorKey: "display_name",
                name: "표시이름",
                size: 500,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {info.getValue()}
                        </SentenceText>
                    );
                },
            },
            {
                id: "title",
                accessorKey: "bib_json.title",
                name: "제목",
                size: 500,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {info.getValue()}
                        </SentenceText>
                    );
                },
            },
            {
                id: "journal",
                accessorKey: "bib_json.journal",
                name: "발행처",
                size: 100,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {info.getValue()}
                        </SentenceText>
                    );
                },
            },
            {
                id: "year",
                accessorKey: "bib_json.year",
                name: "발행연도",
                size: 50,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {info.getValue()}
                        </SentenceText>
                    );
                },
            },
            {
                id: "author",
                accessorKey: "bib_json.author",
                name: "저자",
                size: 100,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {info.getValue()}
                        </SentenceText>
                    );
                },
            },
            {
                id: "abstract",
                accessorKey: "bib_json.abstract",
                name: "초록",
                size: 500,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {info.getValue()}
                        </SentenceText>
                    );
                },
            },
            {
                id: "researches",
                accessorKey: "researches",
                name: "리서치",
                size: 100,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    const researches = info.getValue();
                    if (!researches || researches.length === 0) {
                        return null;
                    }

                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ResearchBox>
                                <SentenceText multiEllipsis size={"xsmall"} textAlign={"center"}>
                                    {researches[0].display_name}
                                </SentenceText>
                            </ResearchBox>
                            {researches.length > 1 && (
                                <ResearchBox>
                                    <SentenceText multiEllipsis size={"xsmall"} textAlign={"center"}>
                                        +{researches.length - 1}
                                    </SentenceText>
                                    <MoreBox className={"moreBox"}>
                                        <GeneralText
                                            margin={"0 0 8px 0"}
                                            size={"small"}
                                            fontWeight={"600"}
                                            textAlign={"left"}
                                        >
                                            리서치 목록
                                        </GeneralText>
                                        <MoreListWrap width={"240px"}>
                                            {researches.slice(1).map((researches, index) => (
                                                <ResearchBox key={index}>
                                                    <SentenceText multiEllipsis size={"xsmall"} textAlign={"center"}>
                                                        {researches.display_name}
                                                    </SentenceText>
                                                </ResearchBox>
                                            ))}
                                        </MoreListWrap>
                                    </MoreBox>
                                </ResearchBox>
                            )}
                        </div>
                    );
                },
            },
            {
                id: "tag_mappings",
                accessorKey: "tag_mappings",
                name: "태그",
                size: 100,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    const tags = info.getValue();
                    if (!tags || tags.length === 0) {
                        return null;
                    }
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <TagBox>
                                <SentenceText multiEllipsis size={"xsmall"} textAlign={"center"}>
                                    {tags[0].name}
                                </SentenceText>
                            </TagBox>
                            {tags.length > 1 && (
                                <TagBox>
                                    <SentenceText multiEllipsis size={"xsmall"} textAlign={"center"}>
                                        +{tags.length - 1}
                                    </SentenceText>
                                    <MoreBox className={"moreBox"}>
                                        <GeneralText
                                            margin={"0 0 8px 0"}
                                            size={"small"}
                                            fontWeight={"600"}
                                            textAlign={"left"}
                                        >
                                            태그 목록
                                        </GeneralText>
                                        <MoreListWrap width={"160px"}>
                                            {tags.slice(1).map((tag, index) => (
                                                <TagBox key={index}>
                                                    <SentenceText multiEllipsis size={"xsmall"} textAlign={"center"}>
                                                        {tag.name}
                                                    </SentenceText>
                                                </TagBox>
                                            ))}
                                        </MoreListWrap>
                                    </MoreBox>
                                </TagBox>
                            )}
                        </div>
                    );
                },
            },
            {
                id: "created_at",
                accessorKey: "created_at",
                name: "추가날짜",
                size: 100,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {moment(info.getValue()).format("YYYY-MM-DD")}
                        </SentenceText>
                    );
                },
            },
            {
                id: "updated_at",
                accessorKey: "updated_at",
                name: "최근활동",
                size: 100,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <SentenceText size={"small"} multiEllipsis webkitLineClamp={"2"} textAlign={"center"}>
                            {moment(info.getValue()).format("YYYY-MM-DD")}
                        </SentenceText>
                    );
                },
            },
            {
                id: "file",
                accessorKey: "file",
                name: "PDF",
                size: 50,
                disableResizing: true,
                header: (info) => getColumnHeaderName(info),
                cell: (info) => {
                    return (
                        <Tooltip position={"left"} message={"PDF보기"}>
                            <Button
                                onlyIcon
                                bgColor={"transparent"}
                                hoverBgColor={"transparent"}
                                onClick={(e) => {
                                    if (info.getValue()) {
                                        navigate(
                                            "/reference/" +
                                                info.row.original.uuid +
                                                "/review/" +
                                                info.row.original.file_uuid,
                                        );
                                    }
                                }}
                            >
                                <Icon
                                    name={"pdf"}
                                    color={info.getValue() ? "var(--color-Key)" : "#C8D0CE"}
                                    size={"18px"}
                                />
                            </Button>
                        </Tooltip>
                    );
                },
            },
        ],
        [],
    );

    const getColumnHeaderName = (info) => {
        return (
            <GeneralText size={"small"} fontWeight={"500"} color={"#2F5B51"}>
                {info.column.columnDef.name}
            </GeneralText>
        );
    };

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [sorting, setSorting] = useState([]);

    const [columnOrder, setColumnOrder] = useState(() => columns.map((c) => c.id));

    // reorder columns after drag & drop
    function handleDragEnd(event) {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            setColumnOrder((columnOrder) => {
                const oldIndex = columnOrder.indexOf(active.id);
                const newIndex = columnOrder.indexOf(over.id);
                return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
            });
        }
    }

    const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

    const [columnVisibility, setColumnVisibility] = useState({
        title: false,
        abstract: false,
        updated_at: false,
    });

    const rerenderTable = React.useReducer(() => ({}), {})[1];

    const table = useReactTable({
        data: bibliographyList,
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        onColumnVisibilityChange: setColumnVisibility,
        state: {
            pagination,
            sorting,
            columnOrder,
            columnVisibility,
        },
    });

    // 뷰 메뉴 토글
    const [activeViewMenu, setActiveViewMenu] = useState(false);
    // 옵션박스 토글
    const [optionBoxActive, setOptionBoxActive] = useState(false);
    // 정렬
    const radioOptions = [
        { id: "-created_at", label: "최근 추가순", value: "-created_at" },
        { id: "-updated_at", label: "최근 활동순", value: "-updated_at" },
        { id: "display_name", label: "표시 이름순", value: "display_name" },
    ];
    // 북마크
    const updateMutation = useBibliographyUpdate();

    const handleBookmarkClick = (bibliography) => {
        updateMutation.mutate({
            id: bibliography.id,
            is_bookmarked: !bibliography.is_bookmarked,
        });
    };

    return (
        <LoginUserLayout>
            <HtmlHead title={"참고문헌 관리"} />

            <Header>
                <HeaderLeftWrap>
                    <TitleText size={"small"} margin={"0 16px 0 0"}>
                        참고문헌 관리
                    </TitleText>
                    <GeneralText size={"small"} margin={"0 2px 0 0"}>
                        전체
                    </GeneralText>
                    <GeneralText size={"small"}>{bibliographyList.length}건</GeneralText>
                </HeaderLeftWrap>
                <HeaderRightWrap ref={buttonWrapRef}>
                    <Button
                        onClick={handleOpenAddButton}
                        width={"132px"}
                        height={"32px"}
                        bgColor={isOpen ? "var(--color-White)" : "var(--color-Button2)"}
                        hoverBgColor={isOpen ? "transparent" : "var(--color-ButtonHover2)"}
                        border={isOpen ? "solid 2px var(--color-Button2)" : "solid 2px transparent"}
                        fontColor={isOpen ? "var(--color-Black)" : "var(--color-White)"}
                        buttonText={"참고문헌 추가"}
                    >
                        <Icon name={"plus"} size={"12"} color={isOpen ? "var(--color-Black)" : "var(--color-White)"} />
                    </Button>
                    <ReferenceAddMenu
                        width={isOpen ? "132px" : "100px"}
                        height={isOpen ? "252px" : "0px"}
                        opacity={isOpen ? "1" : "0"}
                        visibility={isOpen ? "visible" : "hidden"}
                        setModalState={setModalState}
                        modalState={modalState}
                        mutation={bibliographyMutation}
                        fileAddHandler={fileAddHandler}
                    />
                </HeaderRightWrap>
            </Header>

            <Body>
                <BodyHeader>
                    <BodyHeaderLeftSection>
                        <Checkbox
                            margin={"0 8px 0 0"}
                            indeterminate={
                                checkedBibsRef.current.length > 0 &&
                                checkedBibsRef.current.length < bibliographyList?.length
                            }
                            checked={checkedBibsRef.current.length === bibliographyList?.length}
                            onChange={handleCheckboxAllChange}
                        />
                        <Tooltip message={"삭제"}>
                            <Button
                                onlyIcon
                                margin={"0 4px 0 0"}
                                bgColor={"var(--color-Button2)"}
                                hoverBgColor={"var(--color-ButtonHover2)"}
                                onClick={deleteBibliography}
                                disabled={checkedBibsRef.current.length === 0}
                            >
                                <Icon name={"delete"} size={"12"} color={"var(--color-White)"} />
                            </Button>
                        </Tooltip>
                        {/* <Button
                            onlyText
                            margin={"0 4px 0 0"}
                            padding={"0 6px"}
                            width={"auto"}
                            height={"24px"}
                            bgColor={"var(--color-Button1)"}
                            hoverBgColor={"var(--color-ButtonHover1)"}
                            onClick={deleteBibliography}
                            buttonText={"리서치 인용"}
                            disabled={checkedBibsRef.current.length === 0}
                        />
                        <Button
                            onlyText
                            padding={"0 6px"}
                            width={"auto"}
                            height={"24px"}
                            bgColor={"var(--color-Button1)"}
                            hoverBgColor={"var(--color-ButtonHover1)"}
                            onClick={deleteBibliography}
                            buttonText={"태그 적용"}
                            disabled={checkedBibsRef.current.length === 0}
                        /> */}
                    </BodyHeaderLeftSection>
                    <BodyHeaderRightSection>
                        <SearchWrap>
                            <SearchInputBox>
                                <SearchInput
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            navigate(`/reference?filter=${searchValue}`);
                                        }
                                    }}
                                />
                                <SearchButtonWrap>
                                    {searchValue && (
                                        <Tooltip message={"초기화"}>
                                            <Button
                                                onlyIcon
                                                bgColor={"var(--color-White)"}
                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                onClick={(e) => navigate(`/reference`)}
                                            >
                                                <Icon name={"x"} color={"var(--color-SubBlack)"} />
                                            </Button>
                                        </Tooltip>
                                    )}
                                    <Tooltip message={"검색"}>
                                        <Button
                                            onlyIcon
                                            bgColor={"var(--color-White)"}
                                            hoverBgColor={"var(--color-ButtonHover4)"}
                                            onClick={(e) => {
                                                navigate(`/reference?filter=${searchValue}`);
                                            }}
                                        >
                                            <Icon name={"search"} size={"12"} color={"var(--color-SubBlack)"} />
                                        </Button>
                                    </Tooltip>
                                </SearchButtonWrap>
                            </SearchInputBox>
                        </SearchWrap>
                        <BookmarkWrap>
                            <Button
                                onlyIcon
                                margin={"0 8px 0 0"}
                                buttonSize={"24px"}
                                bgColor={"var(--color-White)"}
                                hoverBgColor={"none"}
                                border={
                                    searchValue.includes("is:bookmarked")
                                        ? "solid 2px var(--color-Key)"
                                        : "solid 2px var(--color-DisabledButton)"
                                }
                                onClick={() => {
                                    searchValue.includes("is:bookmarked")
                                        ? navigate(
                                              `/reference?filter=${searchValue.replaceAll("is:bookmarked", "").trim()}`,
                                          )
                                        : navigate(`/reference?filter=${(searchValue + " is:bookmarked").trim()}`);
                                }}
                            >
                                <Icon
                                    name={"bookmark"}
                                    size={"12px"}
                                    color={
                                        searchValue.includes("is:bookmarked")
                                            ? "var(--color-Key)"
                                            : "var(--color-DisabledButton)"
                                    }
                                />
                            </Button>
                            <GeneralText size={"small"}>즐겨찾기만 보기</GeneralText>
                        </BookmarkWrap>
                        <DivideLine />
                        <ViewButtonWrap>
                            <ViewButton
                                activeViewMenu={activeViewMenu}
                                onClick={() => setActiveViewMenu((prevState) => !prevState)}
                            >
                                <Icon
                                    name={viewType === "list" ? "listview" : "cardview"}
                                    color={activeViewMenu ? "var(--color-DarkKey)" : "var(--color-Black)"}
                                    size={"12px"}
                                />
                                <GeneralText
                                    margin={"0 6px"}
                                    size={"regular"}
                                    color={activeViewMenu ? "var(--color-DarkKey)" : "var(--color-Black)"}
                                    fontWeight={"500"}
                                >
                                    {viewType === "list" ? "리스트뷰" : "카드뷰"}
                                </GeneralText>
                                <Icon
                                    name={activeViewMenu ? "x" : "arrowDown"}
                                    color={activeViewMenu ? "var(--color-DarkKey)" : "var(--color-Black)"}
                                    size={activeViewMenu ? "12px" : "12px"}
                                />
                                <ViewMenuContainer menuActive={activeViewMenu}>
                                    <ViewMenuItem
                                        onClick={
                                            viewType === "list" ? () => setViewType("card") : () => setViewType("list")
                                        }
                                    >
                                        <Icon
                                            name={viewType === "list" ? "cardview" : "listview"}
                                            color={"var(--color-DarkKey)"}
                                            size={"12px"}
                                        />
                                        <GeneralText
                                            margin={"0 2px"}
                                            size={"regular"}
                                            color={"var(--color-DarkKey)"}
                                            fontWeight={"500"}
                                        >
                                            {viewType === "list" ? "카드뷰" : "리스트뷰"}
                                        </GeneralText>
                                        <Icon name={"arrowDown"} color={"var(--color-White)"} size={"12px"} />
                                    </ViewMenuItem>
                                </ViewMenuContainer>
                            </ViewButton>
                            <OptionButton
                                optionBoxActive={optionBoxActive}
                                onClick={() => {
                                    if (viewType === "list") {
                                        setOptionBoxActive((prev) => (prev === "list" ? null : "list"));
                                    } else if (viewType === "card") {
                                        setOptionBoxActive((prev) => (prev === "card" ? null : "card"));
                                    }
                                }}
                            >
                                <Icon
                                    name={"option"}
                                    color={optionBoxActive ? "var(--color-DarkKey)" : "var(--color-Black)"}
                                    size={"14px"}
                                />
                                {viewType === "list" && optionBoxActive === "list" && (
                                    <OptionBoxContainer width={"240px"} visible={true}>
                                        <GeneralText
                                            margin={"0 0 16px 0"}
                                            size={"regular"}
                                            fontWeight={"600"}
                                            textAlign={"left"}
                                        >
                                            테이블 항목
                                        </GeneralText>
                                        <CheckOptionWrap>
                                            {table.getAllLeafColumns().map((column) => {
                                                return ["id", "is_bookmarked", "display_name"].includes(
                                                    column.id,
                                                ) ? null : (
                                                    <CheckItemWrap key={column.id}>
                                                        <CheckRadioItemLabel>
                                                            <input
                                                                {...{
                                                                    type: "checkbox",
                                                                    style: { display: "block" },
                                                                    checked: column.getIsVisible(),
                                                                    onChange: column.getToggleVisibilityHandler(),
                                                                }}
                                                            />{" "}
                                                            <GeneralText margin={"0 0 0 4px"} size={"small"}>
                                                                {column.columnDef.name}
                                                            </GeneralText>
                                                        </CheckRadioItemLabel>
                                                    </CheckItemWrap>
                                                );
                                            })}
                                        </CheckOptionWrap>
                                    </OptionBoxContainer>
                                )}
                                {viewType === "card" && optionBoxActive === "card" && (
                                    <OptionBoxContainer width={"120px"} visible={true}>
                                        <GeneralText
                                            margin={"0 0 16px 0"}
                                            size={"regular"}
                                            fontWeight={"600"}
                                            textAlign={"left"}
                                        >
                                            카드정렬
                                        </GeneralText>
                                        <CheckOptionWrap>
                                            {radioOptions.map((option) => (
                                                <RadioItemWrap key={option.id}>
                                                    <CheckRadioItemLabel>
                                                        <input
                                                            type="radio"
                                                            id={option.id}
                                                            name="itemAlign"
                                                            value={option.value}
                                                            checked={order === option.value}
                                                            onChange={() => setOrder(option.value)}
                                                        />
                                                        <GeneralText margin={"0 0 0 4px"} size={"small"}>
                                                            {option.label}
                                                        </GeneralText>
                                                    </CheckRadioItemLabel>
                                                </RadioItemWrap>
                                            ))}
                                        </CheckOptionWrap>
                                    </OptionBoxContainer>
                                )}
                            </OptionButton>
                        </ViewButtonWrap>
                    </BodyHeaderRightSection>
                </BodyHeader>

                {bibliographyList && bibliographyList.length > 0 ? (
                    <BodyContent
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        isFileDragging={isFileDragging}
                    >
                        <DndContext
                            collisionDetection={closestCenter}
                            modifiers={[restrictToHorizontalAxis]}
                            onDragEnd={handleDragEnd}
                            sensors={sensors}
                        >
                            {isFileDragging ? (
                                <BodyContentUploadView>
                                    <UploadDropBox>
                                        <Icon name={"fileUploadOn"} size={"100px"} />
                                        <GeneralText margin={"24px 0 0 0"} size={"large"} textAlign={"center"}>
                                            <strong>참고문헌 파일</strong>을 업로드합니다.
                                        </GeneralText>
                                    </UploadDropBox>
                                </BodyContentUploadView>
                            ) : viewType === "list" ? (
                                <BodyContentListView>
                                    <ListViewTableWrap>
                                        <ListViewTable width={table.getCenterTotalSize()}>
                                            <TableHead>
                                                {table.getHeaderGroups().map((headerGroup) => (
                                                    <TableHeadTr key={headerGroup.id}>
                                                        <SortableContext
                                                            items={columnOrder}
                                                            strategy={horizontalListSortingStrategy}
                                                        >
                                                            {headerGroup.headers.map((header) => (
                                                                <RefTableHeader key={header.id} header={header} />
                                                            ))}
                                                        </SortableContext>
                                                    </TableHeadTr>
                                                ))}
                                            </TableHead>
                                            <TableBody>
                                                {table.getRowModel().rows.map((row) => (
                                                    <tr key={row.id}>
                                                        {row.getVisibleCells().map((cell) => (
                                                            <SortableContext
                                                                key={cell.id}
                                                                items={columnOrder}
                                                                strategy={horizontalListSortingStrategy}
                                                            >
                                                                <DragAlongCell key={cell.id} cell={cell} />
                                                            </SortableContext>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </TableBody>
                                        </ListViewTable>
                                    </ListViewTableWrap>
                                    <ListViewPagination>
                                        <ListViewPageButtonWrap>
                                            <Button
                                                onlyIcon
                                                transparentDisabled
                                                bgColor={"transparent"}
                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                onClick={() => table.firstPage()}
                                                disabled={!table.getCanPreviousPage()}
                                            >
                                                <Icon
                                                    name={"arrowLeftDouble"}
                                                    color={
                                                        !table.getCanPreviousPage()
                                                            ? "var(--color-DisabledText)"
                                                            : "var(--color-SubBlack)"
                                                    }
                                                />
                                            </Button>
                                            <Button
                                                onlyIcon
                                                transparentDisabled
                                                margin={"0 4px"}
                                                bgColor={"transparent"}
                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                onClick={() => table.previousPage()}
                                                disabled={!table.getCanPreviousPage()}
                                            >
                                                <Icon
                                                    name={"arrowLeft"}
                                                    color={
                                                        !table.getCanPreviousPage()
                                                            ? "var(--color-DisabledText)"
                                                            : "var(--color-SubBlack)"
                                                    }
                                                />
                                            </Button>
                                            {Array.from({ length: table.getPageCount() }, (_, index) => (
                                                <Button
                                                    onlyText
                                                    margin={"0 4px"}
                                                    width={"24px"}
                                                    height={"24px"}
                                                    key={index}
                                                    onClick={() => table.setPageIndex(index)}
                                                    bgColor={
                                                        table.getState().pagination.pageIndex === index
                                                            ? "var(--color-Key)"
                                                            : "transparent"
                                                    }
                                                    hoverBgColor={
                                                        table.getState().pagination.pageIndex === index
                                                            ? "var(--color-DarkKey)"
                                                            : "var(--color-ButtonHover4)"
                                                    }
                                                    buttonText={index + 1}
                                                    fontColor={
                                                        table.getState().pagination.pageIndex === index
                                                            ? "var(--color-White)"
                                                            : "var(--color-SubBlack)"
                                                    }
                                                />
                                            ))}
                                            <Button
                                                onlyIcon
                                                transparentDisabled
                                                margin={"0 4px"}
                                                bgColor={"transparent"}
                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                onClick={() => table.nextPage()}
                                                disabled={!table.getCanNextPage()}
                                            >
                                                <Icon
                                                    name={"arrowRight"}
                                                    color={
                                                        !table.getCanNextPage()
                                                            ? "var(--color-DisabledText)"
                                                            : "var(--color-SubBlack)"
                                                    }
                                                />
                                            </Button>
                                            <Button
                                                onlyIcon
                                                transparentDisabled
                                                bgColor={"transparent"}
                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                onClick={() => table.lastPage()}
                                                disabled={!table.getCanNextPage()}
                                            >
                                                <Icon
                                                    name={"arrowRightDouble"}
                                                    color={
                                                        !table.getCanNextPage()
                                                            ? "var(--color-DisabledText)"
                                                            : "var(--color-SubBlack)"
                                                    }
                                                />
                                            </Button>
                                            {/* <span>
                                                <div>Page</div>
                                                <strong>
                                                    {table.getState().pagination.pageIndex + 1} of{" "}
                                                    {table.getPageCount().toLocaleString()}
                                                </strong>
                                            </span>
                                            <span>
                                                | Go to page:
                                                <input
                                                    type="number"
                                                    defaultValue={table.getState().pagination.pageIndex + 1}
                                                    onChange={(e) => {
                                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                        table.setPageIndex(page);
                                                    }}
                                                />
                                            </span> */}
                                        </ListViewPageButtonWrap>
                                        <ListViewPageSelectBoxWrap>
                                            <SelectBox
                                                width={"120px"}
                                                optionWidth={"100%"}
                                                topBottom={"bottom"}
                                                reverse={"180deg"}
                                                value={String(table.getState().pagination.pageSize)}
                                                items={[
                                                    { id: "10", name: "10개씩 보기" },
                                                    { id: "20", name: "20개씩 보기" },
                                                    { id: "30", name: "30개씩 보기" },
                                                    { id: "40", name: "40개씩 보기" },
                                                    { id: "50", name: "50개씩 보기" },
                                                ]}
                                                onChange={(id) => {
                                                    table.setPageSize(Number(id));
                                                }}
                                            />
                                        </ListViewPageSelectBoxWrap>
                                    </ListViewPagination>
                                </BodyContentListView>
                            ) : (
                                <BodyContentCardView>
                                    {bibliographyList.map((item, index) => (
                                        <ReferencePaperItem
                                            key={index}
                                            bibliography={item}
                                            checkedState={checkedBibsRef.current}
                                            handleCheckboxChange={handleCheckboxChange}
                                            searchParams={searchParams}
                                        />
                                    ))}
                                </BodyContentCardView>
                            )}
                        </DndContext>
                    </BodyContent>
                ) : (
                    <>
                        {searchParams.get("filter") ? (
                            <BodyContentBlankView>
                                <GeneralText size={"large"} margin={"0 0 16px 0"}>
                                    일치하는 참고문헌이 없습니다.
                                </GeneralText>
                                <GeneralText size={"small"} textAlign={"center"} color={"var(--color-Grey2)"}>
                                    다른 검색어로 찾아보세요.
                                </GeneralText>
                            </BodyContentBlankView>
                        ) : (
                            <BodyContentBlankView>
                                <FileUploadSection>
                                    <AddFileBox
                                        onDragEnter={handleDragEnter}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                        isFileDragging={isFileDragging}
                                    >
                                        <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                            등록된 참고문헌이 없습니다.
                                        </GeneralText>
                                        <AddBoxIcon>
                                            <Icon
                                                name={isFileDragging ? "fileUploadOn" : "fileUploadOff"}
                                                size={"100px"}
                                            />
                                        </AddBoxIcon>
                                        <GeneralText margin={"0 0 4px 0"} size={"large"} textAlign={"center"}>
                                            <strong>참고문헌 추가 버튼</strong>을 선택하여 참고문헌을 추가하세요.
                                        </GeneralText>
                                        <GeneralText size={"large"} textAlign={"center"}>
                                            또는 <strong>PDF 파일을 이곳에 드래그</strong>하여 참고문헌을 추가할 수
                                            있습니다.
                                        </GeneralText>
                                    </AddFileBox>
                                </FileUploadSection>
                                <AddInfoSection>
                                    <AddInfoBox device={device}>
                                        <AddInfoItem>
                                            <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                                참고문헌을 추가하면 다음 기능을 이용할 수 있습니다.
                                            </GeneralText>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>웹 저장소로 어디서나 접근</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>연관 논문 추천</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>
                                                    리서치에 인용 및 인용문 스타일 전환
                                                </GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>BibTeX 다운로드</GeneralText>
                                            </AddInfoItemRow>
                                        </AddInfoItem>
                                        <AddInfoItem>
                                            <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                                PDF 파일을 추가하면 더 많은 기능들을 사용할 수 있습니다.
                                            </GeneralText>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>서지정보, 태그 자동 추출</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>PDF 리뷰 환경</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoSubItemWrap>
                                                <AddInfoSubItem>
                                                    <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                        &#183;
                                                    </GeneralText>
                                                    <GeneralText {...SubItemTextStyle}>하이라이트 메모</GeneralText>
                                                </AddInfoSubItem>
                                                <AddInfoSubItem>
                                                    <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                        &#183;
                                                    </GeneralText>
                                                    <GeneralText {...SubItemTextStyle}>
                                                        AI 번역, 요약, 설명, 질문
                                                    </GeneralText>
                                                </AddInfoSubItem>
                                            </AddInfoSubItemWrap>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>PDF 자동 요약 및 비평</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoSubItemWrap>
                                                <AddInfoSubItem>
                                                    <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                        &#183;
                                                    </GeneralText>
                                                    <GeneralText {...SubItemTextStyle}>
                                                        연구 주제, 방법, 결과 요약
                                                    </GeneralText>
                                                </AddInfoSubItem>
                                                <AddInfoSubItem>
                                                    <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                        &#183;
                                                    </GeneralText>
                                                    <GeneralText {...SubItemTextStyle}>
                                                        논문에 대한 AI의 비평문 초안
                                                    </GeneralText>
                                                </AddInfoSubItem>
                                            </AddInfoSubItemWrap>
                                        </AddInfoItem>
                                    </AddInfoBox>
                                </AddInfoSection>
                            </BodyContentBlankView>
                        )}
                    </>
                )}
            </Body>
            {modalState.arxivModal && (
                <AbstractModal
                    modalTitle="ArXiv ID로 참고문헌 추가"
                    width={480}
                    exitModal={(e) => setModalState({ ...modalState, arxivModal: !modalState.arxivModal })}
                >
                    <ReferenceAddArxivModal
                        exitModal={(e) =>
                            setModalState({
                                ...modalState,
                                arxivModal: !modalState.arxivModal,
                            })
                        }
                        mutation={bibliographyMutation}
                    />
                </AbstractModal>
            )}
            {modalState.doiModal && (
                <AbstractModal
                    modalTitle="DOI로 참고문헌 추가"
                    width={480}
                    exitModal={(e) => setModalState({ ...modalState, doiModal: !modalState.doiModal })}
                >
                    <ReferenceAddDoiModal
                        exitModal={(e) => setModalState({ ...modalState, doiModal: !modalState.doiModal })}
                        mutation={bibliographyMutation}
                    />
                </AbstractModal>
            )}
            {modalState.pubmedModal && (
                <AbstractModal
                    modalTitle="PubMed ID로 참고문헌 추가"
                    width={480}
                    exitModal={(e) =>
                        setModalState({
                            ...modalState,
                            pubmedModal: !modalState.pubmedModal,
                        })
                    }
                >
                    <ReferenceAddPubmedModal
                        exitModal={(e) =>
                            setModalState({
                                ...modalState,
                                pubmedModal: !modalState.pubmedModal,
                            })
                        }
                        mutation={bibliographyMutation}
                    />
                </AbstractModal>
            )}
            {modalState.bibtexModal && (
                <AbstractModal
                    modalTitle="BibTeX로 참고문헌 추가"
                    width={560}
                    exitModal={(e) =>
                        setModalState({
                            ...modalState,
                            bibtexModal: !modalState.bibtexModal,
                        })
                    }
                >
                    <ReferenceAddBibtexModal
                        exitModal={(e) =>
                            setModalState({
                                ...modalState,
                                bibtexModal: !modalState.bibtexModal,
                            })
                        }
                    />
                </AbstractModal>
            )}
            {showFileUploadModal && (
                <AbstractModal
                    modalTitle="파일 업로드"
                    width={480}
                    exitModal={(e) => {
                        setShowFileUploadModal(false);
                        bibliographiesQuery.refetch();
                    }}
                >
                    <ReferenceFileUploadModal
                        uploadingFiles={uploadingFiles}
                        exitModal={(e) => {
                            setShowFileUploadModal(false);
                            bibliographiesQuery.refetch();
                        }}
                    />
                </AbstractModal>
            )}
        </LoginUserLayout>
    );
}

export default ReferenceListPage;
