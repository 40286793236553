import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { useResearchReferencesCreate, useResearches } from "hooks/queries/useResearch";

const Container = styled.div`
    width: 100%;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 40px 0;
    padding: 6px;
    width: 100%;
    height: 320px;
    max-height: 320px;
    background-color: var(--color-Base1);
`;

const ResearchListWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    height: auto;
    overflow: auto;
`;

const ResearchItem = styled.div`
    margin: 2px;
    padding: 4px;
    max-width: 430px;
    height: 28px;
    background-color: ${(props) => (props.selected ? "var(--color-ResearchActive)" : "var(--color-Research)")};
    border: solid 1px transparent;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: border 80ms ease-in;

    &:hover {
        border: solid 1px var(--color-Key);
    }
`;

const BlankView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

function ReferenceCitationEditModal(props) {
    const { exitModal, citedResearches, referenceId } = props;

    const researchesQuery = useResearches();

    const [selectedIdList, setSelectedIdList] = useState([]);
    const [researches, setResearches] = useState([]);

    const researchReferenceCreateMutation = useResearchReferencesCreate();

    const citedResearchesRef = useRef(null);

    useEffect(() => {
        return () => {
            citedResearchesRef.current = null;
        };
    }, []);

    useEffect(() => {
        if (researchesQuery.data) {
            setResearches(researchesQuery.data);
        }
        if (citedResearchesRef.current !== citedResearches) {
            setSelectedIdList(citedResearches.map((item) => item.id));
            citedResearchesRef.current = citedResearches;
        }
    }, [researchesQuery.data, citedResearches, selectedIdList]);

    const handleCheck = (id) => {
        if (selectedIdList.includes(id)) {
            setSelectedIdList(selectedIdList.filter((item) => item !== id));
        } else {
            setSelectedIdList([...selectedIdList, id]);
        }
    };

    const handleAdd = () => {
        researchReferenceCreateMutation.mutate({
            reference_id: referenceId,
            research_ids: selectedIdList,
        });
        exitModal();
    };

    return (
        <Container>
            <GeneralText size={"regular"}>이 참고문헌을 연결할 리서치를 선택해주세요.</GeneralText>
            <ContentsWrapper>
                {researches?.length > 0 ? (
                    <ResearchListWrap>
                        {researches.map((research, index) => (
                            <ResearchItem
                                key={index}
                                onClick={(e) => handleCheck(research.id)}
                                selected={selectedIdList.includes(research.id)}
                            >
                                <GeneralText ellipsis size={"small"} color={"var(--color-Black)"}>
                                    {research.display_name}
                                </GeneralText>
                            </ResearchItem>
                        ))}
                    </ResearchListWrap>
                ) : (
                    <BlankView>
                        <GeneralText size={"small"} textAlign={"center"} color={"var(--color-DisabledText)"}>
                            아직 리서치가 없습니다.
                        </GeneralText>
                    </BlankView>
                )}
            </ContentsWrapper>
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"적용"}
                leftOnClick={props.exitModal}
                rightOnClick={handleAdd}
            />
        </Container>
    );
}

export default ReferenceCitationEditModal;
